/* eslint-disable import/no-extraneous-dependencies, camelcase, no-undef */
import React from 'react';
import dynamic from 'next/dynamic';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { showLogin } from '../../../redux/slices/authSlice/actions';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import { javaApi } from '../../../utils/AxiosInstance';

import styles from './UserMenu.module.scss';

const RippleButton = dynamic(() => import('../../HomePage/HeroSection/RippleButton'));
const MyAccountDropdown = dynamic(() => import('./MyAccount'));

function UserMenuComponent() {
  const dispatch = useDispatch();
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();

  const isLoading = useSelector((state) => state.auth.loading);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isProfileLoaded = useSelector((state) => state.auth.isProfileLoaded);
  const authState = useSelector((state) => state.auth.isAuthState);
  const authUser = useSelector((state) => state.auth.authUser);

  const handleShowLogin = () => {
    trackMoengageEvent('loginsignup_btn_clicked');
    dispatch(showLogin());
  };

  React.useEffect(() => {
    if ((authState === 'login' || authState === 'signup') && isProfileLoaded) {
      let selectedExam;
      (async () => {
        const response = await javaApi.get('/app-content-ws/api/web/user/exam/preference/list');

        if ('Moengage' in window) {
          Moengage.add_user_name(`${authUser?.firstName} ${authUser?.lastName}`);
          Moengage.add_first_name(authUser?.firstName);
          Moengage.add_email(authUser?.email);
          Moengage.add_mobile(authUser?.mobileNumber);
          Moengage.add_unique_user_id(authUser?.userId || authUser?.id);
          Moengage.update_unique_user_id(authUser?.userId || authUser?.id);
        }

        if (response?.data?.success) {
          selectedExam = response?.data?.data?.[0]?.name;
        }
        trackMoengageEvent('examcategory_selected', {
          exam_selected: selectedExam || 'no exam',
          source_screen: screen,
        });
      })();
    }
    if (authState === 'login' && isProfileLoaded) {
      try {
        trackMoengageEvent('login_btn_tapped', {
          login_status: 'success',
          login_via: 'otp',
        });
      } catch (error) {
        /* empty */
      }
    }
    if (authState === 'signup' && isProfileLoaded) {
      try {
        trackMoengageEvent('signup', {
          signup_via: 'otp',
        });
      } catch (error) {
        /* empty */
      }
    }
  }, [isProfileLoaded]);

  return (
    <div className={styles.userMenuWrapper}>
      {isAuthenticated === false ? (
        !isLoading && (
          <RippleButton
            onClick={handleShowLogin}
            className={styles.userMenuLoginBtn}
            rippleColor="#9e9e9e"
            opacity={0.38}
            width="35px"
            height="35px"
          >
            Login / Signup
          </RippleButton>
        )
      ) : (
        <MyAccountDropdown />
      )}
    </div>
  );
}

export default UserMenuComponent;
